import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import SkuTile from "../../components/SkuTile"
import Image from "../../components/Image"

import styles from "./story.module.scss"

const FW21 = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
      "stellar-velvet-crystals-sandals",
      "console-velvet-sandals",
      "comet-velvet-pumps",
      "satellite-65-velvet-pumps",
      "comet-suede-pumps",
      "solitude-suede-sandals",
      "console-suede-sandals",
      "console-nappa-welt-sandals",
      "solar-65-velvet-sandals",
      "solar-100-velvet-sandals",
      "solar-100-nappa-sandals",
    ].map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        if (sku.images.plp.length > 0 || sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  return (
    <>
      <SEO
        title="Pillow Top"
        description="Fall Winter '21"
        url={location.pathname}
      />

      <ul className={styles.grid}>
        {allSkus.map((sku, i) => (
          <SkuTile
            key={i}
            index={i}
            style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
            sku={sku}
            customStyles={styles}
            origin={`story-pillow-top-fw21`}
          />
        ))}
        <li className={styles.storyItem31}>
          <Image
            image={{
              desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Pillowtop_1_fd3638d2-e45f-48f5-97fc-689e874912c4_1320x.jpg?v=1629408020",
              mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Pillowtop_1_fd3638d2-e45f-48f5-97fc-689e874912c4_850x.jpg?v=1629408020",
            }}
            altText="Pillow Top Story"
          />
        </li>
        <li className={styles.storyItem32}>
          <Image
            image={{
              desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Pillowtop_2_8d9bb79d-7f01-4dc1-bb4b-49abdb23e2d8_1320x.jpg?v=1629408020",
              mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Pillowtop_2_8d9bb79d-7f01-4dc1-bb4b-49abdb23e2d8_850x.jpg?v=1629408020",
            }}
            altText="Pillow Top Story"
          />
        </li>
        <li className={styles.storyItem34}>
          <Image
            image={{
              desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Pillowtop_3_fcd986ea-fc62-443b-8146-21d6f55603aa_1320x.jpg?v=1629408020",
              mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Story_Pillowtop_3_fcd986ea-fc62-443b-8146-21d6f55603aa_850x.jpg?v=1629408020",
            }}
            altText="Pillow Top Story"
          />
        </li>
      </ul>
    </>
  )
}

export default FW21

export const query = graphql`
    query PillowTopFW21Query {
        allProductStyle(filter: {handle: {in: [
            "stellar-velvet-crystals-sandals",
            "console-velvet-sandals",
            "comet-velvet-pumps",
            "satellite-65-velvet-pumps",
            "console-suede-sandals",
            "solitude-suede-sandals",
            "solar-100-velvet-sandals",
            "solar-65-velvet-sandals",
            "comet-suede-pumps",
            "console-nappa-welt-sandals",
            "solar-100-nappa-sandals",
        ]}}) {
            nodes {
                ...PLPStyleFragment
            }
        }
    }
`
